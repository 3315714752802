import axios from "axios";

// const url = process.env.REACT_APP_LOCAL_BASE_URL;
// // const url = process.env.REACT_APP_LIVE_BASE_URL

// const publicUrl = process.env.REACT_APP_AUTH_URLS;

// // const adminUrl = process.env.REACT_APP_DASH_URL;
// const adminUrl = process.env.REACT_APP_BASE_URL;

// const productUrl = process.env.REACT_APP_PRODUCT_URL;

const url = "https://backend.shilergy.com/api/v1";
// const url = process.env.REACT_APP_LIVE_BASE_URL

const publicUrl = "https://backend.shilergy.com/api/auth";

// const adminUrl = process.env.REACT_APP_DASH_URL;
const adminUrl = "https://backend.shilergy.com/api/admin";

const productUrl = "https://backend.shilergy.com/api/product/";

const token1 = localStorage.getItem("adminToken");

export const api = axios.create({
  baseURL: url,
});

export const apis = axios.create({
  baseURL: publicUrl,
  withCredentials: true,
  Authorization: `Bearer ${token1}`,
});

export const adminApi = axios.create({
  baseURL: adminUrl,
  withCredentials: true,
  Authorization: `Bearer ${token1}`,
});

export const productApi = axios.create({
  baseURL: productUrl,
  withCredentials: true,
  Authorization: `Bearer ${token1}`,
});

// Function to refresh the access token
const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshtkn");

  try {
    const response = await axios.post(`${url}/refresh`, null, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    if (response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
    }

    return response.data.access_token;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error; // Propagate the error further
  }
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to token expiration
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "Invalid token"
    ) {
      try {
        const newAccessToken = await refreshToken();

        // Retry the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        // Handle the refresh token error or other errors
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Rest of the code for apis and adminApi can remain the same...
